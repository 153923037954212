import React, { useState, useEffect } from "react";
import "./pastEditionElement.css";
import Table from "../../table/table";
import left from "../../assets/images/left.svg";
import right from "../../assets/images/right.svg";

function PastEditionElement({ title, images, articleLink, results }) {
  const [index, setIndex] = useState(0);

  const nextSlide = () => {
    if (index === images.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  const prevSlide = () => {
    if (index === 0) {
      setIndex(images.length - 1);
    } else {
      setIndex(index - 1);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      nextSlide();
    }, 2000);
  });

  return (
    <div className="past-edition-element">
      <div className="title" onClick={()=>{window.open(articleLink)}}>{`${title} (Article Link)`}</div>

      <div className="carousel-wrapper">
        <a onClick={prevSlide}>
          <img src={left} className="carousel" alt="Prev" />
        </a>
        <div className="Founders">
          <img src={images[index]} alt="Participants" className="main_img" />
          {/* <div className="names">
            {index === 0
              ? "Nitin M Pai"
              : index === 1
              ? "Vempati Shriram "
              : "Bala Ganapathy "}
          </div> */}
        </div>
        <a onClick={nextSlide}>
          <img src={right} className="carousel" alt="Next" />
        </a>
      </div>

      <div className="results">
        {results}
      </div>
    </div>
  );
}

export default PastEditionElement;
