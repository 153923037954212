import "./App.css";
import Home from './home/home'
import Footer from "./footer/footer";
import Gallery from "./Gallery/gallery";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PastEditions from "./pastEditions/pastEditions";


function App() {
  return <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/past-editions" element={<PastEditions/>}/>
      </Routes>
    </BrowserRouter>
  </div>;
}

export default App;
