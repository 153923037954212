import "./home.css";
// import chessPiece from "../assets/logos/cq.png";
import chessPiece from '../assets/logos/chesslogo.png'
import logo from "../assets/logos/ShaastraLogoWhite.png";
import Gallery from "../Gallery/gallery";
import Footer from "../footer/footer";
import { useNavigate } from "react-router-dom";

function Home() {
    const navigate = useNavigate()
  return (
    <div className="home">
      <img src={logo} alt="Shaastra 2023 logo" className="logo" />
      <button id="past-edition-btn" onClick={()=>{navigate('/past-editions')}}>Past Editions</button>

      <main>
        <div>
          {/* <h3 id="fide-tournament">Shaastra's First FIDE Rated Tournament</h3> */}
          <h1 id="rapid-chess">SHAASTRA RAPID CHESS</h1>
          <div>
            <p>
              IITM Chess Club was founded by Alumni Nitin M Pai, Vempati Shriram
              and Bala Ganapathy in early 2016. IIT Madras Chess Club is the
              official channel representing the IIT Madras community in the
              world of chess. The club has successfully organized multiple
              events inside and open to all Chess tournaments over the past 7
              years.
            </p>
          </div>
        </div>
        <img src={chessPiece} alt="Chess pieces" className="chess-piece" />
      </main>
      {/* <div className="register-here">Register for Rapid Chess 2024</div> */}
      <Gallery />
      <Footer />
    </div>
  );
}

export default Home;
