import "./footer.css";
import logo from '../assets/logos/ShaastraLogoWhite.png'

function Footer() {
  return (
          <footer className="footer">
            <div className="image">
             <img src={logo}  className="footer-image" />
            </div>
             <div className="address">
               <div className="add-heading">Address </div>
               <div className="add-content">
                 <span>Students Activity Centre,</span><br/>
                 <span>Taramani IIT Gate Rd,</span><br/>
                 <span>Indian Institute Of Technology Madras,</span><br/>
                 <span>Chennai, Tamil Nadu 600036</span><br/>
                <a className="map-link" href="https://maps.app.goo.gl/i28kjUc7z7ugQHCC6" target="_blank" title="Click for Google Maps directions" rel="noreferrer">Click for Google Maps directions</a>
               </div>
             </div>
            <div className="dev">
              <div className="dev-heading">Developed by</div>
              <div className="bhagya-link">
               <a href="https://www.linkedin.com/in/neelapu-bhagya-sree-b4b010294/" target="_blank" title="Neelapu Bhagya Sree" rel="noreferrer">
                Neelapu Bhagya Sree
               </a>
              </div>
              <div className="pranav-link">
                <a href="https://www.linkedin.com/in/pranav-ram-40415120b" target="_blank" title="Pranav Ram PS" rel="noreferrer">
                Pranav Ram PS
                </a>
              </div>
              <div className="rupali-link">
                <a href="https://www.linkedin.com/in/kolasanakoti-rupali-3048242b6/" target="_blank" title="Kolasanakoti Rupali" rel="noreferrer">
                Kolasanakoti Rupali
                </a>
              </div>
              <div className="shingini-link">
                <a href="https://www.linkedin.com/in/shingini-lahiri-658772290/" target="_blank" title="Shingini Lahiri" rel="noreferrer">
                Shingini Lahiri
                </a>
              </div>
              <div className="aman-link">
                <a href="https://www.linkedin.com/in/aman-kulwal-ab8a39212/" target="_blank" title="Aman kulwal" rel="noreferrer">
                Aman Kulwal
                </a>
              </div>
              <div className="govind-link">
                <a href="https://www.linkedin.com/in/govind-g-s-at003/" target="_blank" title="Govind GS" rel="noreferrer">
                Govind GS
                </a>
              </div>
            </div>
          </footer>
  );
}

export default Footer;