import React from "react";
import logo from "../assets/logos/ShaastraLogoWhite.png";
import { useNavigate } from "react-router-dom";
import "./pastEditions.css";
import PastEditionElement from "./pastEditionElement/pastEditionElement";

import first1 from "../assets/pastEditionPics/1st-1.png";
import second1 from "../assets/pastEditionPics/2nd-1.png";
import second2 from "../assets/pastEditionPics/2nd-2.png";
import second3 from "../assets/pastEditionPics/2nd-3.png";
import second4 from "../assets/pastEditionPics/2nd-4.png";
import third1 from "../assets/pastEditionPics/3rd-1.png";
import fourth1 from "../assets/pastEditionPics/4th-1.png";
import fourth2 from "../assets/pastEditionPics/4th-2.png";
import fourth3 from "../assets/pastEditionPics/4th-3.png";
import fifth1 from "../assets/pastEditionPics/5th-1.png";
import fifth2 from "../assets/pastEditionPics/5th-2.png";
import fifth3 from "../assets/pastEditionPics/5th-3.png";
import fifth4 from "../assets/pastEditionPics/5th-4.png";

function PastEditions() {
  const navigate = useNavigate();

  const pastEditionsList = [
    {
      title: "1st Shaastra Rapid Chess",
      images: [first1],
      articleLink:
        "https://www.chessbase.in/news/shaastra-rapid-fide-rated-tournament",
      results: (
        <div className="first-table">
          {/* first table */}
          <table>
            <tr>
              <th>St.nom</th>
              <th>St. nom</th>
              <th id="name">Name</th>
              <th>city</th>
              <th>fed</th>
              <th>rate. int.</th>
              <th>club/city</th>
            </tr>
            <tr>
              <td rowspan="2">1</td>
              <td rowspan="2">8</td>
              <td>saravana</td>
              <td rowspan="2">1535</td>
              <td rowspan="2">IND</td>
              <td rowspan="2">2293</td>
              <td rowspan="2">CVD</td>
            </tr>
            <tr>
              <td>Krishnan.P</td>
            </tr>
            <tr>
              <td>2</td>
              <td>15</td>
              <td>Akash Pc Iyer</td>
              <td>1907</td>
              <td>IND</td>
              <td>2147</td>
              <td>TN</td>
            </tr>
            <tr>
              <td>3</td>
              <td>4</td>
              <td>Venkatesh M.R.</td>
              <td>LM</td>
              <td>IND</td>
              <td>2420</td>
              <td>PSPB</td>
            </tr>
          </table>
        </div>
      ),
    },
    {
      title: "2nd Shaastra Rapid Chess",
      images: [second1, second2, second3, second4],
      articleLink:
        "https://www.chessbase.in/news/Deepan_wins_Shaastra_Rapid_2018",
      results: (
        <div className="second-table">
          <table>
            <tr>
              <th>Rk.</th>
              <th>This No.</th>
              <th>Name</th>
              <th>Gr</th>
              <th>FED</th>
              <th>FIDE</th>
              <th>Club/City</th>
              <th>Pts.</th>
            </tr>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>Deepan Chakkravarthy J.</td>
              <td>5011</td>
              <td>IND</td>
              <td>2457</td>
              <td>ICF</td>
              <td>9,5</td>
            </tr>
            <tr>
              <td>2</td>
              <td>2</td>
              <td>Vishnu Prasanna. V</td>
              <td>4738</td>
              <td>IND</td>
              <td>2476</td>
              <td>TN</td>
              <td>8,5</td>
            </tr>
            <tr>
              <td>3</td>
              <td>12</td>
              <td>Ram S. Krishnan</td>
              <td>4040</td>
              <td>IND</td>
              <td>2276</td>
              <td>BSNL</td>
              <td>8</td>
            </tr>
          </table>
        </div>
      ),
    },
    {
      title: "3rd Shaastra Rapid Chess",
      images: [third1],
      articleLink:
        "https://www.chessbase.in/news/Al-Muthaiah-wins-Shaastra-Rapid-Rating",
      results: (
        <div className="third-table">
          <table>
            <tr>
              <th>Rk.</th>
              <th>al.nr.</th>
              <th>By Them</th>
              <th>Ry.</th>
              <th>FED</th>
              <th>Rtgl</th>
              <th>Club/City</th>
              <th>p.</th>
            </tr>
            <tr>
              <td>1</td>
              <td>25</td>
              <td>Muthaiah Al</td>
              <td>5156</td>
              <td>IND</td>
              <td>2113</td>
              <td>TN</td>
              <td>9,5</td>
            </tr>
            <tr>
              <td>2</td>
              <td>47</td>
              <td>Pranesh M</td>
              <td>5044</td>
              <td>IND</td>
              <td>1775</td>
              <td>TN</td>
              <td>9</td>
            </tr>
            <tr>
              <td>3</td>
              <td>18</td>
              <td>Senthil Maran K</td>
              <td>6073</td>
              <td>IND</td>
              <td>2195</td>
              <td>TN</td>
              <td>8,5</td>
            </tr>
          </table>
        </div>
      ),
    },
    {
      title: "4th Shaastra Rapid Chess",
      images: [fourth1, fourth2, fourth3],
      articleLink:
        "https://www.chessbase.in/news/shaastra-rapid-fide-rated-tournament",
      results: (
        <div className="fourth-table">
          <table>
            <tr>
              <th>Rk.</th>
              <th>SNo</th>
              <th>Name</th>
              <th>Gr</th>
              <th>FED</th>
              <th>Rtg</th>
              <th>Club/City</th>
              <th>Pts.</th>
            </tr>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>Vishnu Prasanna. V</td>
              <td>97/C</td>
              <td>IND</td>
              <td>2512</td>
              <td>TN</td>
              <td>9,5</td>
            </tr>
            <tr>
              <td>2</td>
              <td>5</td>
              <td>Deepan Chakkravarthy J.</td>
              <td>26/M</td>
              <td>IND</td>
              <td>2470</td>
              <td>ICF</td>
              <td>9</td>
            </tr>
            <tr>
              <td>3</td>
              <td>4</td>
              <td>Kannappan Priyadharshan</td>
              <td>76/M</td>
              <td>IND</td>
              <td>2482</td>
              <td>TN</td>
              <td>8</td>
            </tr>
          </table>
        </div>
      ),
    },
    {
      title: "5th Shaastra Rapid Chess",
      images: [fifth1, fifth2, fifth3, fifth4],
      articleLink:
        "https://www.chessbase.in/news/5th-Shaastra-Rapid-Rating-Open-2023-Round-5-9-report",
      results: (
        <div className="fifth-table">
          <table>
            <tr>
              <th>Rk.</th>
              <th>SNo</th>
              <th>Name</th>
              <th>FED</th>
              <th>Rtgl</th>
              <th>Club/City</th>
              <th>Pts.</th>
            </tr>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>Pranav V</td>
              <td>IND</td>
              <td>2462</td>
              <td>TN</td>
              <td>8</td>
            </tr>
            <tr>
              <td>2</td>
              <td>5</td>
              <td>Ravi Teja S.</td>
              <td>IND</td>
              <td>2357</td>
              <td>AP</td>
              <td>8</td>
            </tr>
            <tr>
              <td>3</td>
              <td>8</td>
              <td>Muthaiah Al</td>
              <td>IND</td>
              <td>2316</td>
              <td>TN</td>
              <td>7,5</td>
            </tr>
          </table>
        </div>
      ),
    },
  ];

  return (
    <div className="past-editions">
      <img src={logo} alt="Shaastra 2023 logo" className="logo" />
      <button
        id="past-edition-btn"
        onClick={() => {
          navigate("/");
        }}
      >
        Rapid Chess 2024
      </button>

      <div className="prev-editions">
        {pastEditionsList.map((pastEdition, ind) => (
          <div>
            <PastEditionElement
              title={pastEdition.title}
              images={pastEdition.images}
              articleLink={pastEdition.articleLink}
              results={pastEdition.results}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PastEditions;
