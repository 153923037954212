import "./gallery.css";
import first from "../assets/images/i1.jpg";
import second from "../assets/images/i2.jpg";
import third from "../assets/images/i3.jpg";
import { useState, useEffect } from "react";
import left from "../assets/images/left.svg";
import right from "../assets/images/right.svg";
import fourth from "../assets/images/i4.jpg";

function Gallery() {
  const images = [first, second, third];
  const [index, setIndex] = useState(0);
  // const [colour, setColour] = useState(0);

  const nextSlide = () => {
    if (index === 2) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  const prevSlide = () => {
    if (index === 0) {
      setIndex(2);
    } else {
      setIndex(index - 1);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      nextSlide();
    }, 2000);
  });

  return (
    <>
      <div>
        <div className="carousel-wrapper">
          <a onClick={prevSlide}>
            <img src={left} className="carousel" alt="Prev" />
          </a>
          <div className="Founders">
            <img src={images[index]} alt="Participants" className="main_img" />
            <div className="names">{index === 0 ? "Nitin M Pai" : index === 1 ? "Vempati Shriram " : "Bala Ganapathy "}</div>
          </div>
          <a onClick={nextSlide}>
            <img src={right} className="carousel" alt="Next" />
          </a>
        </div>
        <div className="dots">
          <span class={`dot ${index === 0 ? "active" : ""}`}></span>
          <span class={`dot ${index === 1 ? "active" : ""}`}></span>
          <span class={`dot ${index === 2 ? "active" : ""}`}></span>
        </div>
      </div>

      <br />

      <div className="chief">
        <img className="chief-img" src={fourth} alt="Cheif Arbiter" />
      </div>
      <div className="chief-text">
        <h3>
          <u className="arbiter-name-link" onClick={()=>{window.open("https://arbiters.fide.com/about-the-arb/team-arb/team-arb-anantharam-rathinam")}}>Chief Arbiter Prof R Anantharam</u>
        </h3>
        <p>
          <b>Chief Arbiter</b> <u>Prof. Rathinam Anantharam</u> Rathinam
          Anantharam is a retired professor of Chemistry. He has served as chief
          arbiter for 6 world championships, one Woman Grand Prix, 13 Asian
          championships, 14 grandmaster tournaments (including Tata Steel India
          2018) and 29 national championships. He is the first A grade arbiter
          from India. Member of FIDE Arbiters’ Commission, Chairman of Arbiters’
          Commission in All India Chess Federation and also International
          Braille Chess Association, Vice President of Tamil Nadu State Chess
          Association, As a chemistry scholar, he has published six articles in
          international journals.
        </p>
      </div>
    </>
  );
}
export default Gallery;
